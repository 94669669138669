<template>
  <div :class="[external ? 'h-full max-h-[22rem]' : '', type == 'requests' && requestEditMode ? 'min-h-[19rem]' : '']">
    <div v-if="type === 'requests'">
      <div
        v-if="requestOpened && requestEditMode"
        class="absolute inset-x-0 z-10 flex h-12 cursor-pointer items-center justify-between overflow-y-hidden rounded-t border-gray-700 bg-gray-800"
        :class="external ? 'bottom-0' : 'bottom-[56px] mb-10 border-b-2'"
      >
        <div
          class="flex h-full w-1/2 items-center justify-center space-x-1 border-r border-gray-700 text-center text-xs font-medium text-gray-200 hover:bg-gray-600"
          @click="
            requestEditMode = false;
            acceptInviteRequests();
          "
        >
          <CheckIcon class="h-4 w-4 text-green-400" />
          <div>Accept</div>
        </div>
        <div
          class="flex h-full w-1/2 items-center justify-center space-x-1 border-r border-gray-700 text-center text-xs font-medium text-gray-200 hover:bg-gray-600"
          @click="
            requestEditMode = false;
            rejectInviteRequests();
          "
        >
          <XMarkIcon class="h-4 w-4 text-red-400" />
          <div>Decline</div>
        </div>
      </div>
      <div
        v-if="!requestOpened"
        class="group flex cursor-pointer items-center justify-between px-3 py-2.5"
        @click="$emit('set-requests-state')"
      >
        <div class="flex items-center space-x-4 group-hover:text-white">
          <UsersIcon class="h-3.5 w-3.5 text-gray-400 group-hover:text-white" />
          <span class="text-xs font-medium tracking-wider text-gray-400 group-hover:text-white">REQUESTS</span>
        </div>
        <div class="flex items-center space-x-2">
          <div
            v-if="channels.length > 0"
            class="flex h-4 w-4 items-center justify-center truncate rounded-full bg-blue-900 p-1 text-xs leading-none text-blue-100"
          >
            {{ channels.length }}
          </div>
          <ChevronRightIcon class="h-3.5 w-3.5 text-gray-400 group-hover:text-white" />
        </div>
      </div>
      <div
        v-if="requestOpened"
        class="flex items-center px-3"
        :class="requestEditMode || sortedChannels.length ? 'py-1.5' : 'py-2.5'"
      >
        <ChevronLeftIcon
          class="h-3.5 w-5 cursor-pointer text-gray-400 hover:text-white"
          @click="
            requestEditMode = false;
            $emit('set-requests-state');
          "
        />
        <div
          class="flex w-full justify-center text-xs font-medium tracking-wider text-white"
          :class="sortedChannels.length ? '' : 'mr-6'"
        >
          REQUESTS
        </div>
        <BaseButton
          v-if="sortedChannels.length"
          size="xs"
          type="secondary"
          @click="
            requestEditMode = !requestEditMode;
            selectedRequests = [];
          "
        >
          {{ requestEditMode ? 'Cancel' : 'Select' }}
        </BaseButton>
      </div>
      <div v-if="requestOpened" class="border-b border-gray-700"></div>
    </div>
    <div
      v-else-if="!requestOpened"
      class="group flex cursor-pointer items-center justify-between px-3 py-2.5"
      @click="openGroupDropdown = !openGroupDropdown"
    >
      <div class="flex items-center space-x-4 group-hover:text-white">
        <div>
          <component
            v-if="selectedFolderIcon"
            :is="
              typeof getIconComponent(selectedFolderIcon) === typeof {}
                ? getIconComponent(selectedFolderIcon)?.c
                : getIconComponent(selectedFolderIcon)
            "
            :icon="getIconComponent(selectedFolderIcon)?.i"
            class="h-3 w-3 text-gray-400 group-hover:text-white"
          />
        </div>
        <BaseTooltip>
          <template #target>
            <span
              class="line-clamp-1 text-xs font-medium uppercase tracking-wider text-gray-400 group-hover:text-white"
            >
              {{ truncate(selectedFolder.split('_').join(' '), 23) }}
            </span>
          </template>
          <template #default v-if="selectedFolder.split('_').join(' ').length > 23">
            {{ selectedFolder.split('_').join(' ') }}
          </template>
        </BaseTooltip>
      </div>
      <div>
        <div class="flex items-center space-x-2">
          <div
            v-if="unreadChannels > 0"
            class="mr-0.5 flex items-center justify-center truncate rounded-full bg-blue-900 p-1 text-xs leading-none text-blue-100"
            :class="unreadChannels > 9 ? 'h-5 w-5' : 'h-4 w-4'"
          >
            <span>{{ unreadChannels > 9 ? '9+' : unreadChannels }}</span>
          </div>
          <Cog8ToothIcon
            v-if="!defaultGroupOptions.map(x => x.id).includes(selectedFolder)"
            size="w-3 h-3 text-gray-400 hover:text-white"
            @click.stop="
              showFolderModal = true;
              updateFolder = selectedFolder;
            "
          />
          <ChevronUpDownIcon class="h-3 w-3 text-gray-400 group-hover:text-white" />
        </div>
        <DropdownAdvance
          v-if="openGroupDropdown"
          custom-styles="min-width: 8rem; max-width: 8rem;"
          custom-classes="right-4 w-32"
          :items="groupOptions"
          :selected="selectedFolder"
          @click.stop=""
          @close="openGroupDropdown = !openGroupDropdown"
          @clicked="setFolder($event.id)"
        />
      </div>
    </div>
    <div v-if="!requestOpened" class="border-b border-gray-700"></div>
    <div
      v-if="(!requestOpened && type !== 'requests') || (requestOpened && type === 'requests')"
      class="overflow-x-hidden"
      :class="[
        external ? 'max-h-full' : '',
        sortedChannels && sortedChannels.length == 0 && selectedEmptyState ? 'overflow-y-hidden' : 'overflow-y-auto'
      ]"
      :style="
        external
          ? miniMessengerStyle()
          : requestEditMode
          ? 'max-height: calc(100vh - 210px)'
          : type == 'requests'
          ? 'max-height: calc(100vh - 170px)'
          : 'max-height: calc(100vh - 200px)'
      "
    >
      <div>
        <ChatSidebarChannel
          v-for="c in sortedChannels"
          :key="c.cid"
          :request-edit-mode="requestEditMode"
          :selected="chosenChannel ? chosenChannel.cid == c.cid : false"
          :channel-cid="c.cid"
          :type="type"
          :external="external"
          @request-selected="toggleFromSelectedRequests(c)"
          @selected="selectedChannel = c.id"
          @create-folder="showFolderModal = true"
        />
      </div>

      <div
        v-if="sortedChannels && sortedChannels.length == 0 && selectedEmptyState"
        class="mx-auto flex flex-col items-center justify-center space-y-2 px-4 text-gray-100"
        :class="external ? 'h-80' : 'h-100'"
      >
        <component
          class="h-16 w-16"
          :class="selectedFolder == 'unread' ? 'text-gray-400' : ''"
          :is="
            typeof getIconComponent(selectedEmptyState.icon)
              ? getIconComponent(selectedEmptyState.icon)?.c
              : getIconComponent(selectedEmptyState.icon)
          "
          :icon="getIconComponent(selectedEmptyState.icon)?.i"
        />
        <div class="text-base font-semibold leading-6">{{ selectedEmptyState.title }}</div>
        <div
          class="text-center font-medium leading-5 text-gray-400"
          :class="[folders.map(x => x.id).includes(selectedFolder) ? '' : 'w-56', external ? 'text-xs' : 'text-sm']"
        >
          {{ selectedEmptyState.subtitle }}
        </div>
        <div class="flex space-x-6 pt-8">
          <BaseButton
            v-if="folders.map(x => x.id).includes(selectedFolder)"
            size="sm"
            type="secondary"
            @press="
              showFolderModal = true;
              updateFolder = selectedFolder;
            "
          >
            Add Chat
          </BaseButton>
          <BaseButton v-if="requestOpened" type="secondary" size="sm" @press="$emit('show-search-modal')">
            Find People
          </BaseButton>
          <BaseButton
            v-else-if="selectedFolder == 'all_chats' || folders.map(x => x.id).includes(selectedFolder)"
            type="secondary"
            size="sm"
            @press="$emitter.$emit('show:create-channel-modal')"
          >
            {{ selectedEmptyState.createButtonText }}
          </BaseButton>
        </div>
      </div>
      <CreateFolderModal
        v-if="showFolderModal"
        :channels="allChannels"
        :update-folder="updateFolder"
        @set-folder="setFolder($event)"
        @close="showFolderModal = false"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import ChatSidebarChannel from './ChatSidebarChannel.vue';
import { truncate } from '@/composeables/filters';
import notify from '@/composeables/notify';
import orderBy from 'lodash/orderBy';
import DropdownAdvance from '../dropdown/DropdownAdvance.vue';
import CreateFolderModal from './ChatCreateFolderModal.vue';
import { useMapGetter } from '@/store/map-state';
import { useStore } from 'vuex';
import { saveNotificationPreference, notInCurrentChat } from '@/composeables/chat';
import useEmitter from '@/composeables/emitter';
import {
  FolderPlusIcon,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconAlt,
  ArchiveBoxIcon,
  EnvelopeOpenIcon,
  UsersIcon,
  ChevronLeftIcon,
  ChevronUpDownIcon,
  ChevronRightIcon,
  Cog8ToothIcon,
  CheckIcon,
  XMarkIcon
} from '@heroicons/vue/20/solid';
import { ChatBubbleLeftRightIcon } from '@heroicons/vue/24/outline';
import CustomIcons from '@/components/CustomIcons.vue';

const iconMap = {
  FolderPlusIcon: FolderPlusIcon,
  ChatBubbleLeftRightIconAlt: ChatBubbleLeftRightIconAlt,
  ArchiveBoxIcon: ArchiveBoxIcon,
  IconUnread: { c: CustomIcons, i: 'unread' },
  EnvelopeOpenIcon: EnvelopeOpenIcon,
  UsersIcon: UsersIcon,
  ChatBubbleLeftRightIcon: ChatBubbleLeftRightIcon
};

const { client, streamUser, folders, mutedChannels } = useMapGetter();
const $store = useStore();
const $emitter = useEmitter();

const getIconComponent = iconName => {
  return iconMap[iconName] || null;
};
const props = defineProps({
  channels: { type: Array, default: () => {} },
  allChannels: { type: Array, default: () => {} },
  type: String,
  showRequests: Boolean,
  chosenChannel: Object,
  external: { type: Boolean, default: false },
  selectedFolder: { type: String, default: 'all_chats' },
  requestOpened: { type: Boolean, default: false }
});

const $emit = defineEmits(['set-folder', 'set-requests-state']);

// REQUESTS
const selectedRequests = ref([]);
function toggleFromSelectedRequests(channel) {
  return selectedRequests.value.includes(channel)
    ? (selectedRequests.value = selectedRequests.value.filter(c => c.cid != channel.cid))
    : selectedRequests.value.push(channel);
}

// INVITE
function rejectInviteRequests() {
  selectedRequests.value.forEach(channel => {
    rejectInvite(channel);
  });
  selectedRequests.value = [];
}

async function rejectInvite(channel) {
  const streamChannel = client.value.channel('messaging', channel.id);
  await streamChannel.rejectInvite();
  if (channel.data.direct_messaging) {
    await streamChannel.delete();
  }
}

function getFilteredInvitedUsers(streamChannel) {
  let invitedUsers = [];
  streamChannel.data.invited_users.forEach(x => {
    if (x.invited_users.includes(streamUser.value.id)) {
      x.invited_users = x.invited_users.filter(u => u != streamUser.value.id);
      if (x.invited_users.length) {
        invitedUsers.push(x);
      }
    } else {
      invitedUsers.push(x);
    }
  });
  return invitedUsers;
}

async function acceptInvite(channel, openChat = false) {
  const streamChannel = client.value.channel('messaging', channel.id);
  localStorage.setItem('acceptRequest', channel.cid);

  await streamChannel.removeMembers([client.value.user.id]);
  await streamChannel.addMembers([client.value.user.id], undefined, {
    hide_history: !channel.data.message_history_access
  });
  await streamChannel.unmute();
  localStorage.setItem('acceptRequest', null);
  await streamChannel.sendMessage({
    text: `{{user:${streamUser.value.id}}} joined this chat.`,
    activity_status: 'join',
    activity_members: [{ id: streamUser.value.id, name: streamUser.value.name }]
  });
  saveNotificationPreference([client.value.user.id], streamChannel, 'all');
  if (openChat) {
    $emitter.$emit('channel:change', channel);
  }
  await streamChannel.updatePartial({
    set: {
      invited_users: getFilteredInvitedUsers(streamChannel)
    }
  });
}

function sendNotification(channels) {
  notify(
    {
      group: 'app',
      type: 'chat_invite',
      payload: {
        title: channels.length == 1 ? 'New Chat Available' : 'New Chats Available',
        channels: channels,
        received_at: 'Just now'
      }
    },
    $store.getters.shortNotification
  );
}

async function acceptInviteRequests() {
  if (selectedRequests.value.length) {
    selectedRequests.value.forEach(async channel => {
      await acceptInvite(channel, channel.cid == selectedRequests.value[0].cid);
    });
    sendNotification(selectedRequests.value);
    selectedRequests.value = [];
  }
}

onMounted(() => {
  $emitter.$on('invite:accept', async channel => {
    if (props.type == 'requests') {
      await acceptInvite(channel);
      sendNotification([channel]);
      $emitter.$emit('channel:change', channel);
    }
  });
  $emitter.$on('invite:reject', channel => {
    if (props.type == 'requests') {
      rejectInvite(channel);
      if (!props.external) {
        if (channel.id == props.chosenChannel.id && sortedChannels.value.length) {
          $emitter.$emit(
            'channel:change',
            sortedChannels.value.find(x => x.cid !== channel.cid)
          );
        } else if (channel.id == props.chosenChannel.id) {
          $emit('set-requests-state');
        }
      } else {
        $emitter.$emit('channel-removed', channel);
      }
    }
  });
});

// GROUP OPTIONS
const defaultGroupOptions = ref([
  {
    id: 'new_folder',
    text: 'New Folder',
    icon: 'FolderPlusIcon',
    group: 0
  },
  {
    id: 'all_chats',
    text: 'All Chats',
    icon: 'ChatBubbleLeftRightIconAlt',
    group: 0
  },
  {
    id: 'archived',
    text: 'Archived',
    icon: 'ArchiveBoxIcon',
    group: 0
  },
  {
    id: 'unread',
    text: 'Unread',
    icon: 'IconUnread',
    group: 0
  }
]);
const groupOptions = ref([]);
const openGroupDropdown = ref(false);
watch(
  () => groupOptions.value,
  (newVal, oldVal) => {
    let selectedFolderVal = groupOptions.value.find(x => x.id == props.selectedFolder);
    selectedFolderIcon.value = selectedFolderVal ? selectedFolderVal.icon : null;
  }
);

// FOLDERS
const showFolderModal = ref(false);

const selectedFolderIcon = ref(null);
const updateFolder = ref(null);
function setFolder(payload) {
  if (payload === 'new_folder') {
    showFolderModal.value = true;
  } else {
    $emit('set-folder', payload);
  }
}

function setFolderOptions() {
  groupOptions.value = [
    ...defaultGroupOptions.value,
    ...folders.value.map(f => {
      return { ...f, group: 1 };
    })
  ];
}

watch(
  () => showFolderModal.value,
  (newVal, oldVal) => {
    if (!showFolderModal.value) {
      updateFolder.value = null;
    }
  }
);
watch(
  () => props.selectedFolder,
  (newVal, oldVal) => {
    let selectedFolderVal = groupOptions.value.find(x => x.id == props.selectedFolder);
    selectedFolderIcon.value = selectedFolderVal ? selectedFolderVal.icon : null;
  }
);
watch(
  () => folders.value,
  (newVal, oldVal) => {
    setFolderOptions();
  },
  { deep: true }
);

onMounted(() => {
  setFolderOptions();
});

onMounted(() => {
  $emitter.$on('create-folder', () => {
    showFolderModal.value = true;
  });
});

// EMPTY STATES
const emptyStates = ref({
  all_chats: {
    icon: 'ChatBubbleLeftRightIconAlt',
    title: 'No Chats Yet',
    subtitle: 'Click on Create Chat below to start a chat',
    createButtonText: '+ Create Chat'
  },
  archived: {
    icon: 'ArchiveBoxIcon',
    title: 'No Archived Chats',
    subtitle: 'When you have archived chats you’ll see them here'
  },
  unread: {
    icon: 'EnvelopeOpenIcon',
    title: 'No Unread Chats',
    subtitle: 'When you have unread chats you’ll see them here'
  },
  requests: {
    icon: 'UsersIcon',
    title: 'No Requests Yet',
    subtitle: 'When you have available requests you’ll see them here'
  },
  others: {
    icon: 'ChatBubbleLeftRightIcon',
    title: 'No Chats Added',
    subtitle: 'Click add chats below to move chats to this folder',
    createButtonText: '+ Create'
  }
});

const selectedEmptyState = computed(() => {
  return props.requestOpened
    ? emptyStates.value['requests']
    : emptyStates.value[props.selectedFolder]
    ? emptyStates.value[props.selectedFolder]
    : emptyStates.value['others'];
});

// CHANNELS
function getRequestUser(channel) {
  return Object.values(channel.state.members).find(x => x.user_id == streamUser.value.id);
}
const sortedChannels = computed(() => {
  let pinnedChannels = [];
  let otherChannels = props.channels;
  if (streamUser.value && Array.isArray(streamUser.value.pinned_channels)) {
    pinnedChannels = props.channels.filter(c => streamUser.value.pinned_channels.includes(c.cid));
    otherChannels = props.channels.filter(c => !streamUser.value.pinned_channels.includes(c.cid));
  }
  let pinnedOrderedChannels = orderBy(
    pinnedChannels,
    o => {
      return new Date(o.state.last_message_at ? o.state.last_message_at : o.data.created_at).getTime();
    },
    ['desc']
  );
  let otherOrderedChannels = [];
  if (props.type == 'requests') {
    otherOrderedChannels = orderBy(
      otherChannels,
      o => {
        return new Date(getRequestUser(o).updated_at).getTime();
      },
      ['desc']
    );
  } else {
    otherOrderedChannels = orderBy(
      otherChannels,
      o => {
        return new Date(o.state.last_message_at ? o.state.last_message_at : o.data.created_at).getTime();
      },
      ['desc']
    );
  }
  return [...pinnedOrderedChannels, ...otherOrderedChannels];
});

onMounted(() => {
  if (props.type === 'all_chats' && sortedChannels.value && sortedChannels.value.length && !props.requestOpened) {
    $emitter.$emit('channel:select', sortedChannels.value[0]);
  }
});

const unreadChannels = computed(() => {
  let sum = 0;
  sortedChannels.value
    .filter(x => !mutedChannels.value.includes(x.cid))
    .map(c => (sum += c.state.unreadCount > 0 && notInCurrentChat(c.cid) ? 1 : 0));
  return sum;
});

// OTHERS
const requestEditMode = ref(false);
function miniMessengerStyle() {
  return 'max-height: calc(100% - 36px)';
}

onUnmounted(() => {
  $emitter.$off('create-folder');
  $emitter.$off('invite:accept');
  $emitter.$off('invite:reject');
});
</script>
